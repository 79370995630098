<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      검색결과
      <div class="search">
        <div
          :class="{
            input_box_n:
              $route.path.includes('/search/goods_search') ||
              $route.path.includes('/search/provider_search'),
            input_box: !$route.path.includes('/search/goods_search'),
          }"
        >
          <input
            type="text"
            name=""
            v-model="searchText"
            :placeholder="placeholder"
            @keydown.enter="goSearch"
          />
        </div>
        <router-link
          to=""
          @click.native="goSearch"
          v-if="
            $route.path.includes('/search/goods_search') ||
            $route.path.includes('/search/provider_search')
          "
          ><img src="@/assets/images/sub/alarm_search_n_bt.png" alt=""
        /></router-link>
        <router-link to="" @click.native="goSearch" v-else
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <ReviewMainMenu
      v-if="mainMenuList.length > 0 && isReview"
      :id="getMenuIndex"
      isMoreMenu
      :reviewMenuList="mainMenuList"
      ref="reviewMainMenu"
      @getMenu="getMenu"
    />
    <MainMenu
      v-if="mainMenuList.length > 0 && (isGoods || isProvider)"
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      ref="reviewMainMenu"
      :allowMenu="allowMenu"
    />
    <template v-if="showPage">
      <router-view ref="search_view" />
    </template>
    <template v-else>
      <div class="ready_menu">
        <p>준비중 입니다.</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewMainMenu from "@/components/review/state/ReviewMainMenu";
import MainMenu from "@/components/shop/menu/MainMenu";
export default {
  data() {
    return {
      first: true,
      showPage: true,
      menuId: this.$route.params.menuId || 1,
      searchText: this.$route.query.search || "",
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
    };
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });

    this.getMainMenu();
  },
  components: {
    ReviewMainMenu,
    MainMenu,
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    isReview() {
      return this.$route.path.includes("review_search");
    },
    isGoods() {
      return this.$route.path.includes("goods_search");
    },
    isProvider() {
      return this.$route.path.includes("provider_search");
    },
    getMenuIndex() {
      const index = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(this.menuId)
      );
      if (index === -1) {
        return 0;
      }
      return index;
    },
    mainMenuSeq() {
      return this.$route.params.menuId;
    },
    placeholder() {
      if (
        this.$route.path.includes("review_search") &&
        parseInt(this.$route.params.menuId) == 1
      ) {
        return "상품 리뷰를 검색하세요.";
      }
      if (
        this.$route.path.includes("review_search") &&
        parseInt(this.$route.params.menuId) == 3
      ) {
        return "뉴스 리뷰를 검색하세요.";
      }
      if (this.$route.path.includes("goods_search")) {
        return "상품을 검색해주세요.";
      }
      if (this.$route.path.includes("provider_search")) {
        return "램가게를 검색해주세요.";
      }
      return "";
    },
  },
  methods: {
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0 && !this.isProvider) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      if (this.$route.path.includes("review_search")) {
        this.$router
          .push(
            `/search/review_search/${this.mainMenuSeq}?search=${this.searchText}`
          )
          .catch(() => {});
      }
      if (this.$route.path.includes("goods_search")) {
        this.$router
          .push(`/search/goods_search?search=${this.searchText}`)
          .catch(() => {});
      }

      this.$nextTick(function () {
        this.$refs.search_view.getSearch(this.searchText, this.mainMenuSeq);
      });
    },
    isUseMenu(menu, number = null) {
      // console.log("isUseMenu", menu, number, this.category_code);
      const menuInfo = this.mainMenuList.filter(
        (main) => parseInt(main.seq) === parseInt(menu)
      );
      // console.log(menuInfo);
      const menuIndex = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(menu)
      );
      // console.log("after", this.category_code);

      return [
        menuInfo[0].use_yn === "Y" ? true : false,
        menuIndex,
        menuInfo[0],
      ];
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
    },
    getMenu(menu) {
      const [useYn, menuIndex, menuInfo] = this.isUseMenu(menu, 1);
      if (useYn) {
        this.showPage = true;
        this.$nextTick(function () {
          if (this.$route.path.includes("review_search") && this.showPage) {
            if (this.$route.path !== `/search/review_search/${menu}`) {
              this.$router.push(
                `/search/review_search/${menu}?search=${this.searchText}`
              );
              this.$refs.search_view.getSearch(this.searchText, menu);
              return false;
            }
          }
          if (this.$route.path.includes("goods_search") && this.showPage) {
            if (this.$route.path !== `/search/goods_search`) {
              this.$router.push(
                `/search/goods_search?search=${this.searchText}`
              );
              this.$refs.search_view.getSearch(this.searchText, menu);
              return false;
            }
          }
        });
      } else {
        this.showPage = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ready_menu {
  text-align: center;
  font-weight: 600;
  padding: 40px 0px 0px 0px;
  font-size: 14px;
}
</style>
